<template>
  <div>
    <DisplayContainer label="Projekt Freigabe bearbeiten">
      <v-form
        ref="form"
        v-model="formValid"
        :disabled="!editable"
      >
        <v-container fluid>
          <v-row align="center">
            <v-col
              class="d-flex"
              cols="12"
              sm="6"
            >
              <v-select
                v-model="taskData.constructionManagementTool"
                :items="['TKI', 'Online-KBL']"
                label="Bausteuerungstool auswählen"
                :rules="[v => !!v || 'Es muss Bausteuerungstool ein ausgewählt werden!']"
                required
                clearable
              ></v-select>
            </v-col>
            <v-col
              class="d-flex"
              cols="12"
              sm="6"
            >
              <v-checkbox
                v-if="taskData.constructionManagementTool === 'TKI'"
                v-model="tkiValidationCheckbox"
                label="TKI-Sharepoint Liste wurde abgearbeitet"
                :rules="[v => (v || taskData.constructionManagementTool !== 'TKI') || 'Die TKI-Sharepoint Liste muss abgearbeitet werden!']"
              ></v-checkbox>
            </v-col>
            <v-col
              class="d-flex"
              cols="12"
              sm="6"
            >
              <v-text-field
                v-model="taskData.constructionManager"
                label="Nachunternehmer (optional)"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-container>
      </v-form>
    </DisplayContainer>
    <div class="mb-4 align-buttons-right">
      <v-btn
        :disabled="!editable || !formValid"
        :loading="isSubmitting"
        color="primary"
        @click="completeTaskDialog = true"
      >
        Projekt freigeben
      </v-btn>
    </div>
    <v-dialog
      v-if="editable"
      v-model="completeTaskDialog"
      width="20vw"
    >
      <v-card>
        <v-card-text class="pt-6">
          <p>
            Mit Freigabe der Aufgabe geht das Projekt <b>{{ project.projektId }} - {{ project.name }}</b> in den nächsten Projektstatus <b :style="{color: nextStatus.color }">{{ nextStatus.description }}</b>.
          </p>
        </v-card-text>
        <v-card-actions class="pb-3">
          <v-btn
            :disabled="isSubmitting"
            small
            color="secondary"
            class="align-center"
            @click="completeTaskDialog = !completeTaskDialog"
          >
            Abbrechen
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            :disabled="!editable"
            :loading="isSubmitting"
            small
            color="primary"
            class="align-center"
            @click="submitTask"
          >
            Projekt freigeben
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import DisplayContainer from '../../components/elements/DisplayContainer.vue';

export default {
  name: 'ActionFreigabeProjektplanung',
  components: {
    DisplayContainer
  },
  props: {
    data: {
      type: Object,
      required: true
    },
    variables: {
      type: Object,
      required: true
    },
    editable: {
      type: Boolean,
      required: true
    }
  },
  data: () => ({
    isSubmitting: false,
    formValid: false,
    completeTaskDialog: false,
    tkiValidationCheckbox: false,
    taskData: {
      constructionManagementTool: null,
      constructionManager: null
    }
  }),
  computed: {
    project() {
      return this.variables.$project;
    },
    step() {
      return this.variables.$currentStep;
    },
    expectedStatus() {
      return this.variables.$currentStep.expectedProjectStatus;
    },
    nextStatus() {
      return this.variables.$currentStep.nextProjectStatus;
    }
  },
  watch: {
    taskData: {
      deep: true,
      handler() {
        setTimeout(this.$refs.form.validate, 1);
      }
    }
  },
  mounted() {
    this.$refs.form.validate();
  },
  methods: {
    submitTask: function () {
      this.isSubmitting = true;
      this.$parent
        .submitTask({
          ...this.taskData,
          newProjectStateDto: this.nextStatus
        })
        .then(() => {
          this.completeTaskDialog = false;
        })
        .finally(() => {
          this.isSubmitting = false;
        });
    }
  }
};
</script>
